// You want to add a new locale? Follow this guide:
// https://github.com/busbud/public-website/wiki/Localization#checklist-to-add-a-new-locale

// Index of Busbud's supported locales
//
// - `key`: key used in URLs and as a unique identifier (BCP 47 standard, all lowercase)
// - `country_code2`: country corresponding to the locale (ISO 3166-1 alpha-2 standard)
// - `label`: label displayed on website (ex: in the locale picker)
// - `full_locale`: the lang + country version of the locale
// - `smartling_key`: key used by Smartling translation service
// - `napi_lang`: lang used in requests to napi
//   (used for progressive rollout of new locales, match the locale key as soon as napi supports that locale)
// - `sitemap_key`: key used in requests to sitemap controller
//   (used for progressive rollout of new locales, match the locale key as soon as sitemap is generated for that locale)
// - `raf_key`: key used for Referral SaaSquatch
//   (Will determine the currency so we need to have a payment gateway for it, falls back to en_US if we don't)

export const supported_locales = {
  de: {
    lang: "de",
    country_code2: "DE",
    currency_code: "EUR",
    full_locale: "de-de",
    smartling_key: "de-DE",
    napi_lang: "de",
    sitemap_key: "de",
    raf_key: "de_DE",
    label: "Deutsch",
    lang_label: "Deutsch",
    contentful_key: "de",
    weekDays: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    firstDayOfWeek: 0
  },
  en: {
    lang: "en",
    country_code2: "US",
    currency_code: "USD",
    full_locale: "en-us",
    smartling_key: "en-US",
    napi_lang: "en",
    sitemap_key: "en",
    raf_key: "en_US",
    label: "English",
    lang_label: "English",
    contentful_key: "en-US",
    weekDays: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    firstDayOfWeek: 0
  },
  "en-ca": {
    lang: "en",
    country_code2: "CA",
    currency_code: "CAD",
    full_locale: "en-ca",
    smartling_key: "en-US",
    napi_lang: "en",
    sitemap_key: "en",
    raf_key: "en_CA",
    label: "English (Canada)",
    lang_label: "English",
    contentful_key: "en-CA",
    weekDays: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    firstDayOfWeek: 0
  },
  "en-gb": {
    lang: "en",
    country_code2: "GB",
    currency_code: "GBP",
    full_locale: "en-gb",
    smartling_key: "en-US",
    napi_lang: "en",
    sitemap_key: "en",
    raf_key: "en_GB",
    label: "English (UK)",
    lang_label: "English",
    contentful_key: "en-GB",
    weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
    firstDayOfWeek: 1
  },
  es: {
    lang: "es",
    country_code2: "ES",
    currency_code: "EUR",
    full_locale: "es-es",
    smartling_key: "es-ES",
    napi_lang: "es",
    sitemap_key: "es",
    raf_key: "es_ES",
    label: "Español (España)",
    lang_label: "Español",
    contentful_key: "es-ES",
    weekDays: ["lu", "ma", "mi", "ju", "vi", "sá", "do"],
    firstDayOfWeek: 1
  },
  "es-419": {
    lang: "es",
    country_code2: "AR",
    currency_code: "ARS",
    full_locale: "es-419",
    smartling_key: "es-LA",
    napi_lang: "es",
    sitemap_key: "es-419",
    raf_key: "es_AR",
    label: "Español (Latinoamérica)",
    lang_label: "Español",
    contentful_key: "es-419",
    weekDays: ["lu", "ma", "mi", "ju", "vi", "sá", "do"],
    firstDayOfWeek: 1
  },
  "es-mx": {
    lang: "es",
    country_code2: "MX",
    currency_code: "MXN",
    full_locale: "es-mx",
    smartling_key: "es-MX",
    napi_lang: "es",
    sitemap_key: "es-mx",
    raf_key: "es_MX",
    label: "Español (Mexico)",
    lang_label: "Español",
    contentful_key: "es-MX",
    weekDays: ["do", "lu", "ma", "mi", "ju", "vi", "sá"],
    firstDayOfWeek: 0
  },
  fr: {
    lang: "fr",
    country_code2: "FR",
    currency_code: "EUR",
    full_locale: "fr-fr",
    smartling_key: "fr-FR",
    napi_lang: "fr",
    sitemap_key: "fr",
    raf_key: "fr_FR",
    label: "Français",
    lang_label: "Français",
    contentful_key: "fr-FR",
    weekDays: ["lu", "ma", "me", "je", "ve", "sa", "di"],
    firstDayOfWeek: 1
  },
  "fr-ca": {
    lang: "fr",
    country_code2: "CA",
    currency_code: "CAD",
    full_locale: "fr-ca",
    smartling_key: "fr-FR",
    napi_lang: "fr",
    sitemap_key: "fr",
    raf_key: "fr_CA",
    label: "Français (Canada)",
    lang_label: "Français",
    contentful_key: "fr-CA",
    weekDays: ["di", "lu", "ma", "me", "je", "ve", "sa"],
    firstDayOfWeek: 0
  },
  it: {
    lang: "it",
    country_code2: "IT",
    currency_code: "EUR",
    full_locale: "it-it",
    smartling_key: "it-IT",
    napi_lang: "it",
    sitemap_key: "it",
    raf_key: "it_IT",
    label: "Italiano",
    lang_label: "Italiano",
    contentful_key: "it-IT",
    weekDays: ["lu", "ma", "me", "gi", "ve", "sa", "do"],
    firstDayOfWeek: 1
  },
  nl: {
    lang: "nl",
    country_code2: "NL",
    currency_code: "EUR",
    full_locale: "nl-nl",
    smartling_key: "nl-NL",
    napi_lang: "nl",
    sitemap_key: "nl",
    raf_key: "nl_NL",
    label: "Nederlands",
    lang_label: "Nederlands",
    contentful_key: "nl",
    weekDays: ["ma", "di", "wo", "do", "vr", "za", "zo"],
    firstDayOfWeek: 1
  },
  pl: {
    lang: "pl",
    country_code2: "PL",
    currency_code: "PLN",
    full_locale: "pl-pl",
    smartling_key: "pl-PL",
    napi_lang: "pl",
    sitemap_key: "pl",
    raf_key: "en_US",
    label: "Polski",
    lang_label: "Polski",
    contentful_key: "pl-PL",
    weekDays: ["Pn", "Wt", "Śr", "Cz", "Pt", "So", "Nd"],
    firstDayOfWeek: 1
  },
  pt: {
    lang: "pt",
    country_code2: "BR",
    currency_code: "BRL",
    full_locale: "pt-br",
    smartling_key: "pt-BR",
    napi_lang: "pt",
    sitemap_key: "pt",
    raf_key: "en_US",
    label: "Português (Brasil)",
    lang_label: "Português",
    contentful_key: "pt-BR",
    weekDays: ["do", "2ª", "3ª", "4ª", "5ª", "6ª", "sá"],
    firstDayOfWeek: 0
  },
  "pt-pt": {
    lang: "pt",
    country_code2: "PT",
    currency_code: "EUR",
    full_locale: "pt-pt",
    smartling_key: "pt-PT",
    napi_lang: "pt",
    sitemap_key: "pt-pt",
    raf_key: "en_US",
    label: "Português (Portugal)",
    lang_label: "Português",
    contentful_key: "pt",
    weekDays: ["2ª", "3ª", "4ª", "5ª", "6ª", "Sá", "Do"],
    firstDayOfWeek: 1
  },
  ru: {
    lang: "ru",
    country_code2: "RU",
    currency_code: "RUB",
    full_locale: "ru-ru",
    smartling_key: "ru-RU",
    napi_lang: "ru",
    sitemap_key: "ru-ru",
    raf_key: "ru_RU",
    label: "Русский",
    lang_label: "Русский",
    contentful_key: "ru",
    weekDays: ["пн", "вт", "ср", "чт", "пт", "сб", "вс"],
    firstDayOfWeek: 1
  },
  sv: {
    lang: "sv",
    country_code2: "SE",
    currency_code: "SEK",
    full_locale: "sv-se",
    smartling_key: "sv-SE",
    napi_lang: "sv",
    sitemap_key: "sv",
    raf_key: "sv_SE",
    label: "Svenska",
    lang_label: "Svenska",
    contentful_key: "sv",
    weekDays: ["må", "ti", "on", "to", "fr", "lö", "sö"],
    firstDayOfWeek: 1
  },
  tr: {
    lang: "tr",
    country_code2: "TR",
    currency_code: "TRY",
    full_locale: "tr-tr",
    smartling_key: "tr-TR",
    napi_lang: "tr",
    sitemap_key: "tr",
    raf_key: "en_US",
    label: "Türkçe",
    lang_label: "Türkçe",
    contentful_key: "tr",
    weekDays: ["Pt", "Sa", "Ça", "Pe", "Cu", "Ct", "Pz"],
    firstDayOfWeek: 1
  },
  zh: {
    lang: "zh",
    country_code2: "CN",
    currency_code: "CNY",
    full_locale: "zh-cn",
    smartling_key: "zh-CN",
    napi_lang: "zh",
    sitemap_key: "zh",
    raf_key: "en_US",
    label: "中文简体",
    lang_label: "中文简体",
    contentful_key: "zh",
    weekDays: ["一", "二", "三", "四", "五", "六", "日"],
    firstDayOfWeek: 1
  }
} as const;

export const supported_sub_locales = {
  "de-ch": {
    parent_locale: "de",
    country_code2: "CH"
  },
  "en-au": {
    parent_locale: "en",
    country_code2: "AU"
  },
  "en-ie": {
    parent_locale: "en-gb",
    country_code2: "IE"
  },
  "en-nz": {
    parent_locale: "en",
    country_code2: "NZ"
  },
  "en-za": {
    parent_locale: "en",
    country_code2: "ZA"
  },
  "es-ar": {
    parent_locale: "es-419",
    country_code2: "AR",
    content_locale: "es-ar"
  },
  "es-bo": {
    parent_locale: "es-419",
    country_code2: "BO"
  },
  "es-cl": {
    parent_locale: "es-419",
    country_code2: "CL"
  },
  "es-co": {
    parent_locale: "es-419",
    country_code2: "CO"
  },
  "es-pe": {
    parent_locale: "es-419",
    country_code2: "PE"
  },
  "fr-be": {
    parent_locale: "fr",
    country_code2: "BE"
  },
  "fr-ch": {
    parent_locale: "fr",
    country_code2: "CH"
  },
  "it-ch": {
    parent_locale: "it",
    country_code2: "CH"
  },
  "nl-be": {
    parent_locale: "nl",
    country_code2: "BE"
  }
} as const;

// If the sub_locale share the same country than the parent_locale, it's basically the same page
// So we're redirecting to the main page. eg: `/es-ar` has the same content than `/es-419`
export function isSubLocaleEquivalentToParent(
  sub_locale_code: SupportedSubLocale
): boolean {
  const sub_locale = supported_sub_locales[sub_locale_code];
  return (
    supported_locales[sub_locale.parent_locale].country_code2 ===
    sub_locale.country_code2
  );
}

export const getLocalesByLanguages = (
  languages: string[]
): SupportedLocale[] => {
  return [...supported_locales_set].filter(locale =>
    languages.includes(supported_locales[locale].lang)
  );
};

export type SupportedLocale = keyof typeof supported_locales;
export type SupportedLanguage =
  (typeof supported_locales)[SupportedLocale]["lang"];

export type SupportedSubLocale = keyof typeof supported_sub_locales;
export type SubLocaleDetails =
  (typeof supported_sub_locales)[SupportedSubLocale];
export type SupportedLocalesOrSubLocale = SupportedSubLocale | SupportedLocale;

export function getLanguageOfLocale(
  locale: SupportedLocale
): SupportedLanguage {
  return supported_locales[locale].lang;
}

export const supported_locales_set = new Set<SupportedLocale>(
  Object.keys(supported_locales) as SupportedLocale[]
);
export function isSupportedLocale(l: string): l is SupportedLocale {
  return supported_locales_set.has(l as SupportedLocale);
}

export type ContentLocale = SupportedLocale | "es-ar";

// Most commonly used BCP 47 language tags taken from:
// https://www.techonthenet.com/js/language_tags.php
const BCP47_language_tags = [
  "ar-sa",
  "bn-bd",
  "bn-in",
  "cs-cz",
  "da-dk",
  "de-at",
  "de-ch",
  "de-de",
  "el-gr",
  "en-au",
  "en-ca",
  "en-gb",
  "en-ie",
  "en-in",
  "en-nz",
  "en-us",
  "en-za",
  "es-ar",
  "es-cl",
  "es-co",
  "es-es",
  "es-mx",
  "es-us",
  "fi-fi",
  "fr-be",
  "fr-ca",
  "fr-ch",
  "fr-fr",
  "he-il",
  "hi-in",
  "hu-hu",
  "id-id",
  "it-ch",
  "it-it",
  "jp-jp",
  "ko-kr",
  "nl-be",
  "nl-nl",
  "no-no",
  "pl-pl",
  "pt-br",
  "pt-pt",
  "ro-ro",
  "ru-ru",
  "sk-sk",
  "sv-se",
  "ta-in",
  "ta-lk",
  "th-th",
  "tr-tr",
  "zh-cn",
  "zh-hk",
  "zh-tw"
] as const;

export type RecognizedLocale =
  | SupportedLocale
  | (typeof BCP47_language_tags)[number];
const recognized_locales_list = [
  ...new Set([...supported_locales_set, ...BCP47_language_tags])
];

const latin_american_locales_list = [
  "es-ar",
  "es-bo",
  "es-br",
  "es-bz",
  "es-cl",
  "es-co",
  "es-cr",
  "es-cu",
  "es-do",
  "es-ec",
  "es-gt",
  "es-hn",
  "es-mx",
  "es-ni",
  "es-pa",
  "es-pe",
  "es-pr",
  "es-py",
  "es-sv",
  "es-us",
  "es-uy",
  "es-ve"
] as const;

export type LatinAmericanLocale = (typeof latin_american_locales_list)[number];
export const supported_locales_keys = [...supported_locales_set];
export const supported_sub_locale_keys = [
  ...new Set<SupportedSubLocale>(
    Object.keys(supported_sub_locales) as SupportedSubLocale[]
  )
];
export const supported_locale_and_sub_locale_keys = [
  ...supported_locales_keys,
  ...supported_sub_locale_keys
];

const supported_latin_american_locales_list = intersection(
  latin_american_locales_list,
  supported_locales_keys
);
const unsupported_latin_american_locales_list = without(
  latin_american_locales_list,
  supported_locales_keys
);

export const Locales = {
  default_locale: "en",
  content_locales_list: [...supported_locales_keys, "es-ar"],
  supported_locales_keys,
  supported_locale_and_sub_locale_keys,
  recognized_locales_list,
  supported_locales,
  supported_sub_locales,
  latin_american_locales_list: {
    supported: supported_latin_american_locales_list,
    unsupported: unsupported_latin_american_locales_list
  }
} as const;

export default Locales;

/** Returns a new array that contains items from array `a` without any items from array `b`
 *
 * @param a
 * @param b
 **/
function without<A, B>(a: readonly A[], b: readonly B[]): Exclude<A, B>[] {
  return a.filter((x): x is Exclude<A, B> => (b as unknown[]).indexOf(x) < 0);
}

/** Returns a new array that contains items from array `a` without any items from array `b`
 *
 * @param a
 * @param b
 **/
function intersection<A, B>(a: readonly A[], b: readonly B[]): (A & B)[] {
  return a.filter((value): value is A & B => (b as unknown[]).includes(value));
}

import { ModalId } from "@app/types/modal";
import { AmplitudeEvent } from "@app/types/tracking/common";
import { ClosedModal, ViewModal } from "@app/types/tracking/modal";

import { TrackingAction } from "./constants/tracking-action";

export function showedModal(
  modal_type: ModalId | string
): AmplitudeEvent<ViewModal> {
  return {
    action: TrackingAction.ViewModal,
    details: { modal_type }
  };
}

export function closedModal(
  modal_type?: ModalId | string
): AmplitudeEvent<ClosedModal> {
  return {
    action: TrackingAction.ClosedModal,
    details: {
      ...(modal_type && { modal_type })
    }
  };
}

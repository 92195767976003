export default class HeaderDrawer {
  public constructor(
    private drawer: null | Element = null,
    private openBtn: null | Element = null,
    private closeBtn: null | Element = null
  ) {
    this.drawer = drawer || document.querySelector("#header-drawer");
    this.openBtn = openBtn || document.querySelector("#header-drawer-open");
    this.closeBtn = closeBtn || document.querySelector("#header-drawer-close");
    this._initListeners();
  }

  private _initListeners() {
    this.openBtn?.addEventListener("click", this._toggleDrawer.bind(this));
    this.closeBtn?.addEventListener("click", this._toggleDrawer.bind(this));
  }

  private _toggleDrawer() {
    this.drawer?.classList.toggle("open");

    document.body.style.touchAction = this.drawer?.classList.contains("open")
      ? "none"
      : "";
    document.body.style.overflow = this.drawer?.classList.contains("open")
      ? "hidden"
      : "auto";
  }
}

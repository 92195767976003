import {
  ClickMenu,
  ClosedModal,
  SawModal
} from "@app/types/tracking/authentication";
import { AmplitudeEvent } from "@app/types/tracking/common";

import { TrackingAction } from "./constants/tracking-action";

type View = "sign_in" | "sign_up" | "forgot_password";

export const sawModal = (view: View = "sign_in"): AmplitudeEvent<SawModal> => ({
  action: TrackingAction.SawModal,
  details: { modal_type: view }
});

export const closedModal = (): AmplitudeEvent<ClosedModal> => ({
  action: TrackingAction.ClosedModal,
  details: { modal_type: "authentication" }
});

export const clickMenu = (
  item: "signin" | "account",
  menu_location: "footer" | "header"
): AmplitudeEvent<ClickMenu> => ({
  action: TrackingAction.ClickMenu,
  details: {
    category: "account",
    menu_location,
    menu_item: item
  }
});
